<template>
  <div class="flex flex-col">
    <div class="customer-white">
      <div class="mb-2">
        <el-button v-if="!isUpdate" @click="HANDLE_NEW_STOCK_DIALOG"
          >Ajouter un stockage</el-button
        >
      </div>
      <skeleton-list :row="8" v-if="listLoading" />
      <el-table :data="items" v-else style="width: 100%">
        <el-table-column prop="date" label="Nom de l'entrepôt" width="480">
          <template slot-scope="scope">
            <span> {{ scope.row.warehouse_name }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="quantity_in"
          label="Quantité disponible"
          width="200"
          align="right"
        >
          <template slot-scope="{ row }">
            <div>
              {{ row.quantity }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!isUpdate"
          width="140"
          align="right"
          label="Action"
        >
          <template slot-scope="{ row }">
            <el-button
              @click="HANDLE_UPDATE_STOCK(row)"
              type="info"
              circle
              size="mini"
              icon="el-icon-edit"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <adjustement
      v-if="dialogAdjustement"
      :visible="dialogAdjustement"
      :articleId="articleId"
      :warehouseCode="adjustement.warehouseCode"
      :quandityOnHand="adjustement.quandityOnHand"
      @close="handleCloseDialogAdjustement"
      @success="handleSuccesAdjustement"
    />
    <el-dialog
      title="NOUVEAU LIEU DE STOCKAGE "
      :visible.sync="dialogNewStock"
      width="30%"
      top="4vh"
      :close-on-click-modal="false"
    >
      <el-form
        :model="stock"
        :rules="rulesStock"
        ref="stockForm"
        :label-position="labelPosition"
      >
        <el-form-item label="Entrepôts" prop="warehouse_code">
          <el-select
            class="w-full"
            v-model="stock.warehouse_code"
            placeholder="selectionner l'entrepôt "
          >
            <el-option
              v-for="item in warehouses"
              :key="item.id"
              :label="`${item.name}`"
              :value="item.code"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="quantity" label="Quantité de stock">
          <el-input-number
            class="w-full"
            :controls="false"
            v-model="stock.quantity"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <div slot="footer" class="border-t pt-2 dialog-footer">
        <el-button @click="dialogNewStock = false">Annuler</el-button>
        <el-button
          type="primary"
          :loading="buttonLoading"
          @click="CREATE_STOCK('stockForm')"
          >Enregistrer</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fetchStockOnHand, fetchWarehouse, createStock } from "@/api/stock";
import Adjustement from "../component/Adjustement.vue";
import SkeletonList from "@/components/Skeleton/list";
export default {
  name: "Stock",
  props: {
    articleId: {
      type: String,
      required: true,
    },
    isUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: { Adjustement, SkeletonList },
  data() {
    return {
      items: [],
      warehouses: [],
      dialogAdjustement: false,
      dialogNewStock: false,
      buttonLoading: false,
      labelPosition: "top",
      stockOnHand: 0,
      listLoading: false,
      stock: {
        article_id: this.articleId,
        warehouse_code: "",
        quantity: 0,
      },
      adjustement: {
        warehouseCode: "",
        quandityOnHand: 0,
      },
      rulesStock: {
        quantity: [
          {
            required: true,
            message: "La quantité est obligatoire",
            trigger: "blur",
          },
          {
            type: "number",
            message: "Entrer un chiffre",
            trigger: "blur",
          },
          // { min: 1, message: "Entrer un montant  valide", trigger: "blur" },
        ],
        warehouse_code: [
          {
            required: true,
            message: "Veuillez selectionner un entrepôt",
            trigger: "change",
          },
          // { min: 1, message: "Entrer un montant  valide", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  mounted() {
    this.FETCH_STOCK_ON_HAND();
  },
  methods: {
    async FETCH_STOCK_ON_HAND() {
      this.listLoading = true;
      await fetchStockOnHand(this.articleId).then((res) => {
        setTimeout(() => {
          this.items = res.data;
          this.listLoading = false;
        }, 1.5 * 1000);
      });
    },

    HANDLE_UPDATE_STOCK(row) {
      this.dialogAdjustement = true;
      this.adjustement.warehouseCode = row.warehouse_code;
      this.adjustement.quandityOnHand = row.quantity;
    },

    async fetchWarehouse() {
      await fetchWarehouse()
        .then((res) => {
          this.warehouses = res.data;
        })
        .catch((error) => {
          this.listLoading = false;
          this.$message.error(error.data.message);
        });
    },
    HANDLE_NEW_STOCK_DIALOG() {
      this.fetchWarehouse();
      this.dialogNewStock = true;
    },
    HANDLE_UPDATE_TOTAL_STOCK() {
      var total = 0;
      this.items.forEach((el) => {
        total = total + el.quantity;
      });
      this.$emit("total-item", total);
    },
    CREATE_STOCK(stockForm) {
      this.buttonLoading = true;
      this.$refs[stockForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          createStock(this.stock)
            .then(() => {
              setTimeout(() => {
                this.buttonLoading = false;
                this.dialogNewStock = false;
                this.FETCH_STOCK_ON_HAND();
                this.HANDLE_UPDATE_TOTAL_STOCK();
                this.$message({
                  title: "Success",
                  message: "Ajouter avec succès ",
                  type: "success",
                  duration: 2000,
                });
              }, 1.5 * 1000);
            })
            .catch((err) => {
              setTimeout(() => {
                if (err.data.status === 400) {
                  this.$message({
                    message: "Veuillez renseigner les informations obligatoire",
                    type: "warning",
                  });
                } else if (err.data.status === 409 || err.data.status === 409) {
                  this.$message({
                    message: err.data.message,
                    type: "warning",
                  });
                } else {
                  this.$message({
                    message: "Erreur serveur",
                    type: "danger",
                  });
                }
              }, 1.5 * 1000);
              this.buttonLoading = false;
            });
        }
      });
    },

    handleSuccesAdjustement(e) {
      console.log(this.items);
      const stock = this.items.find(
        (el) => el.warehouse_code === this.adjustement.warehouseCode
      );
      console.log(stock);
      stock.quantity = e.quantity;
      this.handleCloseDialogAdjustement();
      this.HANDLE_UPDATE_TOTAL_STOCK();
    },
    handleCloseDialogAdjustement() {
      this.dialogAdjustement = false;
    },
    handleOpenDialogAdjustement() {
      this.dialogAdjustement = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
