<template>
  <!-- List Payment en Bl -->
  <el-dialog
    title="AJUSTER LE STOCK"
    :visible="visible"
    width="30%"
    top="4vh"
    :close-on-click-modal="false"
  >
    <el-form
      :model="adjustmentStock"
      :rules="rules"
      ref="adjustmentForm"
      :label-position="labelPosition"
    >
      <div class="flex">
        <el-form-item prop="raison" label="Motif" class="mr-2 flex-1">
          <el-select
            style="width: 100%"
            v-model="adjustmentStock.raison"
            placeholder="Veuillez selectionner"
          >
            <el-option
              v-for="(item, index) in raisons"
              :key="index"
              :value="item"
              :label="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="flex-1"
          prop="quantity"
          label="Quantité ajustée (- ou +)"
        >
          <el-input-number
            style="width: 100%"
            :controls="false"
            v-model="adjustmentStock.quantity"
            @blur="handleModifyQuantity"
          ></el-input-number>
        </el-form-item>
      </div>

      <el-form-item label="Nouvelle quantite">
        <el-input-number
          class="w-full"
          :controls="false"
          v-model="adjustmentStock.quantity_new"
          :disabled="true"
          @blur="handleModifyQuantityNew"
        ></el-input-number>
      </el-form-item>
      <div class="font-semibold">Quantité en stock : {{ quandityOnHand }}</div>
    </el-form>

    <div slot="footer" class="border-t pt-2 dialog-footer">
      <el-button @click="handleCloseDialog">Annuler</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="CREATE_ADJUSTEMENT('adjustmentForm')"
        >Enregistrer</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { fetchStockOnHand, createAdjustmentStock } from "@/api/stock";
export default {
  name: "ADJUSTEMENT-SINGLE",
  props: {
    visible: Boolean,
    articleId: {
      type: String,
      required: true,
    },
    warehouseCode: {
      type: String,
      required: true,
    },
    quandityOnHand: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      loadingStock: false,
      labelPosition: "top",
      raisons: [
        "Recevoir des articles",
        "Recomptage des stocks",
        "Perte",
        "Dommages",
        "Eclatement",
      ],
      adjustmentStock: {
        raison: "",
        note: "",
        quantity_new: 0,
        quantity: 0,
        items: [
          {
            article_id: "",
            quantity: 0,
            warehouse_code: "",
          },
        ],
      },

      rules: {
        raison: [
          {
            required: true,
            message: "Le motif est obligatoire",
            trigger: "change",
          },
        ],
        quantity: [
          {
            required: true,
            message: "La quantité est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    // this.fetchStockOnHand();
  },
  methods: {
    CREATE_ADJUSTEMENT(adjustmentForm) {
      this.$refs[adjustmentForm].validate((valid) => {
        if (valid) {
          this.loading = true;
          console.log(this.adjustmentStock[0]);
          this.adjustmentStock.items[0].quantity =
            this.adjustmentStock.quantity;
          this.adjustmentStock.items[0].warehouse_code = this.warehouseCode;
          this.adjustmentStock.items[0].article_id = this.articleId;

          createAdjustmentStock(this.adjustmentStock)
            .then(() => {
              setTimeout(() => {
                this.loading = false;
                this.$emit("success", {
                  articleId: this.articleId,
                  quantity: this.adjustmentStock.quantity_new,
                });
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      });
    },

    async fetchStockOnHand() {
      this.loadingStock = true;
      await fetchStockOnHand(this.articleId)
        .then((res) => {
          setTimeout(() => {
            let stocks = res.data;
            const stock = stocks.find((el) => el.has_primary === true);
            this.adjustmentStock[0].warehouse_code = stock.warehouse_code;
            this.adjustmentStock[0].article_id = this.articleId;
            this.adjustmentStock.quantity_onhand = stock.quantity;
            this.loadingStock = false;
          }, 1000);
        })
        .catch((error) => {
          this.$message.error(error.data.message);
        });
    },
    handleCloseDialog() {
      this.$emit("close", false);
    },
    handleModifyQuantity() {
      this.adjustmentStock.quantity_new =
        this.quandityOnHand + this.adjustmentStock.quantity;
    },
    handleModifyQuantityNew() {
      this.adjustmentStock.quantity =
        this.adjustmentStock.quantity_new - this.quandityOnHand;
    },
  },
};
</script>

<style></style>
